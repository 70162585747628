/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'lazysizes'
import objectFitImages from 'object-fit-images'
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider

objectFitImages()
