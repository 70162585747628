import { combineReducers } from 'redux'

const initialState = {
  lang: 'fr',
  currentSearchQuery: '',
  currentSearchFilters: null,
  currentMainTag: '',
  currentprofile: '',
  laboEmptyLabo: '',
  laboEmptyOther: '',
  projectsRegion: null,
  projectsStatus: null,
  projectsListMap: null,
  mapCenter: null,
  mapView: 'list',
  currentProject: null,
  currentProjectPoint: null,
  currentProjectSheet: null,
  mapZoom: 0,
  projectsInClusters: [],
  map: null,
  mapAddress: '',
  mapAddressCoords: null,
  mapAddressFilled: false,
  mapAddressFilledFirstTime: false,
  filtersChange: 0,
  filtersThemesNumber: 0,
  filtersYearNumber: 0,
  filtersTypeNumber: 0,
  filtersHolderNumber: 0,
  filtersProfilesNumber: 0,
  hasFixedProfileNav: false,
  hasLaboOpen: false,
  hasMainNavOpen: false,
  hasProfileNavOpen: false,
  savedSearchState: {},
}

const rootReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case 'CHANGE_LANG':
      return {
        ...state,
        lang: action.payload,
      }
    case 'SET_CURRENT_SEARCH_QUERY':
      return {
        ...state,
        currentSearchQuery: action.payload,
      }
    case 'SET_CURRENT_SEARCH_FILTERS':
      return {
        ...state,
        currentSearchFilters: action.payload,
      }
    case 'SET_CURRENT_MAIN_TAG':
      return {
        ...state,
        currentMainTag: action.payload,
      }
    case 'SET_PROJECTS_REGION':
      return {
        ...state,
        projectsRegion: action.payload,
      }
    case 'SET_PROJECTS_STATUS':
      return {
        ...state,
        projectsStatus: action.payload,
      }
    case 'SET_PROJECTS_LIST_MAP':
      return {
        ...state,
        projectsListMap: action.payload,
      }
    case 'SET_MAP_CENTER':
      return {
        ...state,
        mapCenter: action.payload,
      }
    case 'SET_CURRENT_PROFILE_TAG':
      return {
        ...state,
        currentprofile: action.payload,
      }
    case 'SET_LABO_EMPTY_LABO':
      return {
        ...state,
        laboEmptyLabo: action.payload,
      }
    case 'SET_LABO_EMPTY_OTHER':
      return {
        ...state,
        laboEmptyOther: action.payload,
      }
    case 'SET_MAP_VIEW':
      return {
        ...state,
        mapView: action.payload,
      }
    case 'SET_CURRENT_PROJECT':
      return {
        ...state,
        currentProject: action.payload,
      }
    case 'SET_CURRENT_PROJECT_POINT':
      return {
        ...state,
        currentProjectPoint: action.payload,
      }
    case 'SET_CURRENT_PROJECT_SHEET':
      return {
        ...state,
        currentProjectSheet: action.payload,
      }
    case 'SET_MAP_ZOOM':
      return {
        ...state,
        mapZoom: action.payload,
      }
    case 'SET_PROJECTS_IN_CLUSTERS':
      return {
        ...state,
        projectsInClusters: action.payload,
      }
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload,
      }
    case 'SET_MAP_ADDRESS':
      return {
        ...state,
        mapAddress: action.payload,
      }
    case 'SET_MAP_ADDRESS_COORDS':
      return {
        ...state,
        mapAddressCoords: action.payload,
      }
    case 'SET_MAP_ADDRESS_FILLED':
      return {
        ...state,
        mapAddressFilled: action.payload,
      }
    case 'SET_MAP_ADDRESS_FILLED_FIRST_TIME':
      return {
        ...state,
        mapAddressFilledFirstTime: action.payload,
      }
    case 'SET_FILTERS_CHANGE':
      return {
        ...state,
        filtersChange: action.payload,
      }
    case 'SET_FILTERS_THEMES_NUMBER':
      return {
        ...state,
        filtersThemesNumber: action.payload,
      }
    case 'SET_FILTERS_YEAR_NUMBER':
      return {
        ...state,
        filtersYearNumber: action.payload,
      }
    case 'SET_FILTERS_HOLDER_NUMBER':
      return {
        ...state,
        filtersHolderNumber: action.payload,
      }
    case 'SET_FILTERS_PROFILES_NUMBER':
      return {
        ...state,
        filtersProfilesNumber: action.payload,
      }
    case 'SET_FILTERS_TYPE_NUMBER':
      return {
        ...state,
        filtersTypeNumber: action.payload,
      }
    case 'SET_HAS_FIXED_PROFILE_NAV':
      return {
        ...state,
        hasFixedProfileNav: action.payload,
      }
    case 'SET_HAS_LABO_OPEN':
      return {
        ...state,
        hasLaboOpen: action.payload,
      }
    case 'SET_HAS_MAIN_NAV_OPEN':
      return {
        ...state,
        hasMainNavOpen: action.payload,
      }
    case 'SET_HAS_PROFILE_NAV_OPEN':
      return {
        ...state,
        hasProfileNavOpen: action.payload,
      }
    case 'SAVE_SEARCH_STATE':
      return {
        ...state,
        savedSearchState: action.payload,
      }
    default:
      return state
  }
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
